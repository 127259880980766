import { graphql } from "gatsby"
import React from "react"
import PortableText from '@sanity/block-content-to-react'
import GlobalStyles from '../styles/GlobalStyles'
import styled from "styled-components"
import Img from 'gatsby-image'
import {Helmet} from "react-helmet"
import favicon32 from '../images/Favicon-32.png'
import favicon128 from '../images/Favicon-128.png'
import favicon152 from '../images/Favicon-152.png'
import favicon167 from '../images/Favicon-167.png'
import favicon180 from '../images/Favicon-180.png'
import favicon192 from '../images/Favicon-192.png'
import favicon196 from '../images/Favicon-196.png'

const Homepage = styled.div`
  h1,h2{
    color:var(--light);
    font-size:1em;
    text-transform:uppercase;
    font-weight:normal;
    letter-spacing:0.04em;
    margin-top:0;
  }

  .headshot{
  max-width:40%;
  filter:grayscale(1.0) brightness(1.2) contrast(0.7);

  @media screen and (min-width:1600px){
    max-width:25%;
  }
}
  p:first-child{
    margin-top:0;
  }
  .bio{

  }
  .work{
    margin-top:6em;
  }

  section{

    
    display:grid;
    
    @media  (min-width:767px){
      grid-template-columns:1fr 1fr;
    }

    @media (min-width:800px){
      grid-template-columns: 1fr 60%;
    }

    @media (min-width:1200px){
    grid-template-columns:1fr 50%;

    }
  }

  .contact{
    @media screen and (min-width:767px){
      margin-top:20vh;
    }
    grid-column: 1;
    /* grid-row:2; */

    ul{
      list-style:none;
      padding:0;

      li{
        font-size:16px;
        line-height:1.5;
        letter-spacing:0.02em;
        a{
          text-decoration:none;
          color:var(--light);
        }
      }

    }


  }
 
  .bio{
    @media screen and (min-width:900px){
      margin-top:-2em;
    }
    /* grid-row:1; */
    /* grid-column:2; */
  }

  footer{
    font-size:0.6em;
    letter-spacing: 0.03em;
    margin-top:10em;
  }

`

export default function Home({data: {homepage}}) {
  const data = homepage.nodes[0]
  return <Homepage>
    <GlobalStyles/>
    <Helmet title={data.title}>
      <meta name="description" content={data.description}/>
      <link rel="icon" href={favicon32}  sizes="32x32"/>
      <link rel="icon" href={favicon128} sizes="57x57"/>
      <link rel="icon" href={favicon152} sizes="76x76" />
      <link rel="icon" href={favicon167} sizes="96x96"/>
      <link rel="icon" href={favicon180} sizes="128x128"/>
      <link rel="icon" href={favicon192} sizes="192x192"/>
      <link rel="icon" href={favicon196} sizes="228x228"/>

      <link rel="shortcut icon" href={favicon196} sizes="196x196"/>

      <link rel="apple-touch-icon" href={favicon128} sizes="120x120"/>
      <link rel="apple-touch-icon" href={favicon152} sizes="152x152"/>
      <link rel="apple-touch-icon" href={favicon192} sizes="180x180"/>

      <meta property="og:type" content="website" />
      <meta property='og:image' content={data.headshot.asset.url} />

      <meta name="twitter:title" content={data.title}/>
      <meta name="twitter:image" content={data.headshot.asset.url}/>

    </Helmet>
    <section>
      <h1>{data.title}</h1>

      <div className="contact">

        <Img className="headshot" fluid={data.headshot?.asset?.fluid}/>
        <ul>
          {data.links.map((link, i) => (
            <li>
              <a target="_blank" href={link.url}>{link.linkText}</a>
            </li>
          ))}
        </ul>
      </div>
      <PortableText className="bio" blocks={data._rawBio}/>

    </section>

    <section className="work">
      <h2>{data.workTitle}</h2>

      <PortableText blocks={data._rawWork}/>
    </section>
    <footer>
          © {new Date().getFullYear()}, Designed and built by 
          {` `}
          <a href="http://micahbarrett.work/" target="_blank">Micah Barrett</a>
        </footer>
    </Homepage>
}

export const query = graphql`
  query HomePageQuery{
    homepage: allSanityHomepage{
      nodes{
        id
        title
        _rawBio
        _rawWork
        description
        workTitle
        links{
          _key
          linkText
          url
        }
        headshot{
          asset {
            _key
            url
            fluid(maxWidth: 800, maxHeight: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`