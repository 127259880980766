import { createGlobalStyle } from 'styled-components'
import font from '../assets/fonts/soehne-web-kraftig.woff'
import font2 from '../assets/fonts/soehne-web-kraftig-kursiv.woff'

const GlobalStyles = createGlobalStyle`
    *{
        box-sizing:border-box;
    }


    :root{
        --black: rgb(60,60,60);
        --light: rgb(140,140,140);
        --white: rgb(245,245,245);


        --bg-color: ${props =>
          props.backgroundColor ? props.backgroundColor : 'red'};
        --text-color: ${props => (props.textColor ? props.textColor : 'black')};
        
    }

    html{
        font-size:18px;
        line-height:1.35;
        @media (min-width:550px){
            font-size:24px;
        }
      

        @media (min-width:1200px){
            font-size:30px;
        }

        @media (min-width: 1600px){
            font-size:32px;
        }
    }

    body{
        padding:1em;
        @media screen and (min-width:800px){
        }
    }

    .gatsby-image-wrapper img[src*=base64\\,] {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
        /* filter: blur(100px); */
    }

    @font-face{
        font-family: 'Soehne';
        src:url(${font2});
        font-weight:400,
        font-style:italic;
    }

    @font-face{
        font-family: 'Soehne';
        src: url(${font});
        font-weight:400;
        font-style:normal;
    }

   

    figcaption{
        font-size:0.5em;
    }

    body{
        margin:0;
        background:var(--white);
        font-family: 'Soehne','Helvetica', Arial, sans-serif,-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        color:var(--black);
        font-size:1rem;
        -webkit-font-smoothing:antialiased;
    }

    h1{
        font-weight:400;
    }

    a{
        text-decoration:none;
        color:var(--light);
        
    }
`

export default GlobalStyles
